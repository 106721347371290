import React from 'react';

const Bann = () => {
    return (
        <div className="content-bann">

            <div className="content-bann_image">
                        {/* <div className="bann-back"> */}
                            <a
                                href="https://sex-shops.ar/?sIDCatalogoWEB=5b56f3b7-5517-4ef2-bcc7-5374121739be"
                                target="_blank"
                                className="bann-back"
                                rel="noopener noreferrer"
                            >
                                <p className="sex">Sex Shop Chocolate</p>
                                <p className="hash">#AlServicioDelAmor</p>
                            </a>

                            {/* <p>Al Servicio del Amor</p> */}
                        {/* </div> */}
            </div>
        </div>
    );
}

export default Bann;